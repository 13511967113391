<template>
  <v-container>
    <!-- 그룹 관리 테이블 -->
     
    <v-row no-gutters>
      <v-col cols="12">
        <h1>[관리자] 공지사항 관리</h1>
      </v-col>
      <v-col cols="12" style="margin:20px 0;">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>가이드</v-expansion-panel-header>
            <v-expansion-panel-content>
              - 공지사항을 관리하는 화면입니다.<br>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- Collapsible Description -->
    <v-row no-gutters>
    </v-row>
    <!-- Table (takes the remaining space) -->
    <v-row no-gutters class="flex-grow-1">
      <v-col cols="12" style="text-align:right; margin:10px 0;">
        <v-btn color="primary" @click="openDialog(null)">공지사항 추가</v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          hide-default-footer
          :page.sync="page"
          :items-per-page="pagePer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <!-- 좌측 타이틀 -->
              <v-toolbar-title>공지사항 목록</v-toolbar-title>
              <span class="ml-3"> 공지사항 수 : {{ totalCount }}</span>
              
              <!-- 우측 검색 및 그룹 추가 영역 -->
              <v-spacer></v-spacer>
              
              <!-- 검색 필드와 버튼을 정렬 -->
              <v-text-field
                v-model="searchText"
                label="공지사항 제목"
                outlined
                dense
                hide-details
                style="max-width: 250px; margin-right: 10px;"
                @keyup.enter="fetchNotice"
              ></v-text-field>
              <v-btn color="primary" @click="fetchNotice" style="margin-right: 10px;">검색</v-btn>
            </v-toolbar>
            <div class="d-flex flex-column justify-end align-content-end mr-3" style="align-items: end;" >
              <span class="mr-5">페이지 당 조회수</span>
              <v-select
                  v-model="pagePer"
                  :items="pagePerItems"
                  outlined
                  dense
                  hide-details
                  return-object
                  single-line
                  style="max-width: 150px;"
                  class="custom-select"
              ></v-select>
            </div>
          </template>
          <template v-slot:item="{ item}">
            <tr class="text-center">
              <td> {{item.no}} </td>
              <td> {{item.title}} </td>
              <td> {{customizedContent(item.content)}}</td>
              <td> {{item.createTime.substring(0,16)}} </td>
              <td>
                <v-btn style="margin-top:5px; color:#FFF; margin-right: 5px;" small color="#1e9ae5" @click="openDialog(item)">수정</v-btn>
                <v-btn style="margin-top:5px; color:#FFF; margin-right: 5px;" small color="red" @click="deletegroup(item)">삭제</v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-pagination v-model="page" :length="pageCount" @input="fetchNotice"></v-pagination>
      </v-col>
    </v-row>

    <!-- 공지사항 추가/수정 Dialog -->
    <v-dialog v-model="dialog" max-width="1024px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ selectedItem ? '공지사항 수정' : '공지사항 추가' }}</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-text-field
              label="공지사항 제목"
              outlined
              v-model="formData.title"
            ></v-text-field>
            <vue-editor v-model="formData.content"></vue-editor>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">취소</v-btn>
          <v-btn color="primary" @click="savegroup">저장</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      color="primary"
      variant="tonal"
      :timeout="2000"
    >
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { wrapFetch } from '@/config'

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      html: '',
      dialog: false,
      selectedItem: null,
      formData: {
        no: '',
        title: '',
        content: ''
      },
      headers: [
        { text: 'ID', value: 'no', align: 'center'},
        { text: '제목', value: 'title', align: 'center' },
        { text: '내용', value: 'content', align: 'center' },
        { text: '작성일', value: 'createTime', align: 'center' },
        { text: '', value: 'actions', sortable: false },
      ],
      items: [], // 서버에서 받아올 사용자 목록
      snackbar: false,
      snackText: '',
      searchText: '', // 검색어 입력
      pagePerItems: [5, 10, 20, 100],
      totalCount: 0,
      page: 1,
      pagePer: 5,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalCount / this.pagePer)
    },
  },
  methods: {
    openDialog(item) {
      if (item) {
        this.selectedItem = item;
        this.formData = { ...item };
      } else {
        this.selectedItem = null;
        this.resetFormData();
      }
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },

    resetFormData() {
      this.formData = {
        no: '',
        title: '',
        contents: ''
      };
    },

    async savegroup() {
      let self = this;
      if (this.selectedItem) {
        wrapFetch("/notice", "PUT", this.formData)
        .then(() => {
          self.snackText = "공지사항 수정이 완료되었습니다.";
          self.snackbar = true;
          self.dialog = false;
          self.resetFormData();
          self.fetchNotice();
        })
      } else {
        wrapFetch("/notice", "POST", this.formData)
        .then(() => {
          self.snackText = "공지사항 추가가 완료되었습니다.";
          self.snackbar = true;
          self.dialog = false;
          self.resetFormData();
          self.fetchNotice();
        })
      }
    },
    async deletegroup(item) {
      if(confirm(`${item.title} 공지사항을 정말 삭제하시겠습니까?`)){
        wrapFetch(`/notice?no=${item.no}`, "DELETE")
        .then(() => {
          self.snackText = "공지사항 삭제가 완료되었습니다.";
          self.snackbar = true;
          this.fetchNotice();
        })
      }
      
    },
    async fetchNotice() {
      let self = this;
      wrapFetch(`/notice?searchText=${self.searchText}&page=${self.page}&pagePer=${self.pagePer}`, "GET")
      .then(e => {
        self.totalCount = e.totalCount
        self.items = [];
        self.items.push(...e.data);
      })
    },
    customizedContent(content) {
      let text = content.replace(/<[^>]*>/g, '')
      if (text.length > 15) {
        return text.substring(0, 15) + '...'; // 10자 초과 시 10자까지만 보여주고 ... 추가
      } else {
        return text
      }
    }
  },
  mounted() {
    this.fetchNotice();
  },
};
</script>

<style scoped>
.mb-4 {
  margin-bottom: 16px;
}
</style>
